// common/_funfact.scss

.fun-fact {
    text-align: center;
    margin: 20px;
  
    p {
      margin-top: 10px;
      font-size: 1.2em;
    }
  }