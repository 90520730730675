.product-page {
  padding: 20px;
  margin-top: 80px; // Adjust this value based on your navbar height

  h1 {
    font-size: 2em;
    margin-bottom: 10px;
    text-align: center;
  }

  p {
    font-size: 1.2em;
    margin-bottom: 20px;
    text-align: center;
  }

  .product-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-left: 18%;
    margin-right: 18%;

    @media (max-width: 1280px) {
      grid-template-columns: repeat(2, 1fr); // 2 kolom untuk ukuran layar sedang
      margin-left: 15%;
      margin-right: 5%;
    }

    @media (max-width: 726px) {
      grid-template-columns: 1fr; // 1 kolom untuk ukuran layar kecil (mobile)
      margin-left: 12%;
      margin-right: 2%;
    }
  }

  .product-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    max-width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    .product-image-container {
      width: 100%;
      padding-top: 75%; // 4:3 aspect ratio
      position: relative;

      .product-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
    }


    img {
      max-width: 100%;
      border-radius: 8px;
    }

    .product-info {
      margin-top: 10px;
      text-align: center;

      h2 {
        font-size: 1.5em;
        margin-bottom: 10px;
      }

      p {
        font-size: 1em;
        margin-bottom: 10px;
      }

      .price {
        font-size: 1.2em;
        font-weight: bold;
        color: #e91e63;
      }
    }
      .product-button {
        padding: 10px 20px;
        font-size: 1em;
        color: #fff;
        background-color: #e91e63;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #d81b60;
      }
    }
  }
}