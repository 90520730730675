/* src/scss/team.scss atau di dalam index.scss jika tidak ada file khusus untuk Team */
.team-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
  }
  
  @media (min-width: 768px) {
    .team-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (min-width: 1024px) {
    .team-grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }