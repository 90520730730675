.cs_contact_widget {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .contact-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
  }
  
  .contact-icon {
    margin-right: 10px;
  }
  
  .contact-text {
    display: inline-block;
  }